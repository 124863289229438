import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HeaderService } from '../../services/header.service';
import { StatusType, TicketElement, TicketsService } from '../tickets.service';
import {
  Observable,
  Subscription,
  finalize,
  forkJoin,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { HeaderComponent } from '../../components/header/header.component';
import { NotificationService } from '../../services/notification.service';
import {
  Attachment,
  UploadComponent,
} from '../../components/upload/upload.component';
import { MatButtonModule } from '@angular/material/button';
import { VendorElement, VendorsService } from '../../vendors/vendors.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { DialogService } from '../../services/dialog.service';
import { TicketAttachmentComponent } from '../ticket-attachment/ticket-attachment.component';
import { MatDialog } from '@angular/material/dialog';
import { TicketLinkMailComponent } from '../ticket-link-mail/ticket-link-mail.component';
import { TicketReminderComponent } from '../ticket-reminder/ticket-reminder.component';
import { TicketStatusComponent } from './ticket-status/ticket-status.component';
import { TicketClosingComponent } from './ticket-closing/ticket-closing.component';
import { MatMenuModule } from '@angular/material/menu';
import { ImagePopupComponent } from '../../components/image-popup/image-popup.component';

interface ImageAttachments {
  id: number;
  name: string;
  url?: SafeUrl;
  loaded?: boolean;
}

@Component({
  selector: 'app-ticket-detail',
  standalone: true,
  imports: [
    AsyncPipe,
    DatePipe,
    NgClass,
    MatButtonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    HeaderComponent,
    UploadComponent,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatMenuModule,
  ],
  providers: [HttpClient],
  templateUrl: './ticket-detail.component.html',
  styleUrl: './ticket-detail.component.scss',
})
export class TicketDetailComponent {
  vendors = [];
  departments = [];
  assignees = [];
  statuses = [];
  ticketForm = this.fb.group({
    businessName: ['', [Validators.required]],
    businessAddress: ['', [Validators.required]],
    supportTypeId: [0, [Validators.required]],
    memberId: [0, [Validators.required]],
    departmentId: [0, [Validators.required]],
    vendorId: [0, []],
    issueOrConcern: ['', [Validators.maxLength(500)]],
    assignedTo: ['', []],
    statusTypeId: [0, [Validators.required]],
    attachments: this.fb.array([]),
    closingStatement: [' ', []],
  });
  attachments: ImageAttachments[] = [];
  messages: {
    sender: 'receiver' | 'sender';
    name: string;
    message: string;
    time: Date;
  }[] = [];

  ticket$!: Observable<TicketElement | null>;
  initiatedComments = false;
  ticketId: number;
  message = new FormControl('');
  sendingMessage = false;
  dialogSubscription!: Subscription;
  isEditing = false;
  isAttachmentEditing = false;
  statusType = StatusType;
  previousFormValue: any;
  previousAttachments: Attachment[] = [];
  vendorList: VendorElement[] = [];
  public isButtonLoading: boolean = false;

  statusTypes$ = this.ticketsService.getStatusTypes();
  memberTypes: any;
  supportTypes$ = this.ticketsService.getSupportTypes();
  departmentTypes$ = this.ticketsService.getDepartmentTypes();
  currentTicketStatus: any;
  stma: string = 'South Texas Merchants Association (STMA)';
  isLoading: boolean = false;

  readonly dialog = inject(MatDialog);
  constructor(
    private notificationService: NotificationService,
    private ticketsService: TicketsService,
    private vendorService: VendorsService,
    private fb: FormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private headerService: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService
  ) {
    this.matIconRegistry.addSvgIcon(
      'note-edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/note-edit.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'send-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/send-outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/add-blue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'more-vertical',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/more-vertical.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cross.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'dropdown',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/dropdown.svg'
      )
    );
    this.headerService.setShowBackIcon(true);
    this.ticketId = +this.route.snapshot.paramMap.get('id')!;
    this.ticketsService.getMembers().subscribe((response: any) => {
      this.memberTypes = response.members;
    });
  }

  ngOnInit(): void {
    this.fetchComments();
    this.getVendorList();
    this.fetchTicketDetail().subscribe();
  }

  getVendorList() {
    this.vendorService.getVendors(0).subscribe((data: any) => {
      this.vendorList = data.vendors;
    });
  }

  fetchTicketDetail() {
    this.ticket$ = this.ticketsService.getTicketById(this.ticketId);
    return this.ticketsService.getTicketById(this.ticketId).pipe(
      tap((ticket) => {
        if (ticket) {
          const formValues = {
            supportTypeId: ticket.supportType.value,
            departmentId: ticket.department?.value,
            statusTypeId: ticket.statusType.value,
            vendorId: ticket.vendor.value,
            memberId: ticket.member.value,
            assignedTo: ticket.assignedTo,
            closingStatement: ticket.closingStatement
              ? ticket.closingStatement
              : ' ',
            issueOrConcern: ticket.issueOrConcern,
          };
          this.currentTicketStatus = ticket.statusType.key;
          this.attachments = ticket.attachments;
          this.ticketForm.patchValue(formValues as any);
          this.ticketForm.disable();
          if (!this.attachments.length) {
            this.isAttachmentEditing = true;
          }
          if (ticket.attachments) {
            this.fetchAttachments(ticket.attachments);
          }
          // this.previousFormValue = this.ticketForm.value;
          // this.previousAttachments = [...this.attachments];
        }
      })
    );
  }

  fetchAttachments(attachmentsObj: any) {
    attachmentsObj.forEach((attachment: any, index: any) => {
      this.ticketsService
        .getAttachmentThumbnail(this.ticketId, attachment.id)
        .subscribe(
          (res: Blob) => {
            const url = window.URL.createObjectURL(res);
            this.attachments[index].url =
              this.domSanitizer.bypassSecurityTrustResourceUrl(url);
            this.attachments[index].loaded = false;
          },
          (error) => {
            console.error('Error:', error);
          }
        );
    });
  }

  onImageLoad(index: number): void {
    this.attachments[index].loaded = true;
  }

  showImage(attachmentId: any, loaded: boolean) {
    if (loaded) {
      this.isLoading = true;
      this.ticketsService
        .getAttachments(this.ticketId, attachmentId)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (res: Blob) => {
            const url = window.URL.createObjectURL(res);
            const imageUrl =
              this.domSanitizer.bypassSecurityTrustResourceUrl(url);
            this.dialog.open(ImagePopupComponent, {
              data: {
                imagePath: imageUrl,
              },
              panelClass: 'image-popup',
            });
          },
          (error) => {
            console.error('Error:', error);
          }
        );
    }
  }

  fetchComments() {
    this.ticketsService
      .getComments(this.ticketId)
      .subscribe((comments: any) => {
        this.initiatedComments = !!comments.length;
        this.messages = comments.map((comment: any) => {
          return {
            message: comment.comment,
            sender:
              comment.audit.createdBy === 'Zeeshan' ? 'sender' : 'receiver',
            name: this.getInitials(comment.audit.createdBy),
            time: comment.audit.createdDate,
          };
        });
      });
  }

  getInitials(fullName: string): string {
    const nameParts = fullName.split(' ');
    let initials = '';
    if (nameParts.length > 0) {
      initials += nameParts[0][0].toUpperCase();
    }
    if (nameParts.length > 1) {
      initials += nameParts[1][0].toUpperCase();
    }
    return initials;
  }

  onInitiateComments() {
    this.initiatedComments = true;
  }

  updateStatus(statusId: number) {
    const assignedTo = this.ticketForm.get('assignedTo');
    const closingStatement = this.ticketForm.get('closingStatement');
    assignedTo?.clearValidators();
    closingStatement?.clearValidators();
    if (statusId == 2) {
      const dialogRef = this.dialogService.open({
        component: TicketStatusComponent,
        title: 'Change Status to In Progress',
        actionLabel: 'Confirm',
        hideCloseIcon: true,
        themeModal: true,
      });
      this.dialogSubscription?.unsubscribe();
      this.dialogSubscription = this.dialogService.data$.subscribe(
        (formData: any) => {
          dialogRef.close();
          if (formData.assignedTo == null || formData.assignedTo.trim()=='') {
            this.notificationService.error(
              'Please add assignee'
            );
            return;
          }
          let body = {
            statusTypeId: 2,
            assignedTo: formData.assignedTo,
          };
          this.isLoading = true;
          this.ticketsService
            .updateStatus(this.ticketId, body)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
              next: () => {
                assignedTo?.setValidators(Validators.required);
                this.ticketForm.controls.statusTypeId.setValue(2);
                this.currentTicketStatus = 'IN-PROGRESS';
                this.ticketForm.controls.assignedTo.setValue(
                  formData.assignedTo
                );
                this.ticketForm.controls.statusTypeId.setValue(2);
                this.notificationService.success(
                  'Ticket status updated successfully'
                );
              },
              error: (err) => {
                this.notificationService.error(err.message);
              },
            });
        }
      );
    } else if (statusId == 3) {
      const dialogRef = this.dialogService.open({
        component: TicketClosingComponent,
        title: 'Closing Statement',
        actionLabel: 'Submit',
        hideCloseIcon: true,
        themeModal: true,
      });

      this.dialogSubscription?.unsubscribe();
      this.dialogSubscription = this.dialogService.data$.subscribe(
        (formData: any) => {
          dialogRef.close();
          let body = {
            statusTypeId: 3,
            assignedTo: formData.assignedTo,
            closingStatement: formData.closingStatement,
          };
          this.isLoading = true;
          this.ticketsService
            .updateStatus(this.ticketId, body)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
              next: () => {
                closingStatement?.setValidators(Validators.required);
                this.ticketForm.controls.closingStatement.setValue(
                  formData.closingStatement
                );
                this.ticketForm.controls.statusTypeId.setValue(3);
                this.currentTicketStatus = 'CLOSED';
                this.notificationService.success(
                  'Ticket status updated successfully'
                );
              },
              error: (err) => {
                this.notificationService.error(err.message);
              },
            });
        }
      );
    }

    assignedTo?.updateValueAndValidity();
    closingStatement?.updateValueAndValidity();
  }

  edit(value: boolean) {
    if (value) {
      this.isEditing = true;
      this.ticketForm.enable();
    } else {
      this.isAttachmentEditing = true;
    }
  }

  sendComment() {
    if (!this.message.value?.trim().length) {
      return;
    }
    this.sendingMessage = true;
    const userDetails = JSON.parse(localStorage.getItem('account') || '');
    const payload = {
      comment: this.message.value,
    };
    this.ticketsService.sendComment(this.ticketId, payload).subscribe(() => {
      this.fetchComments();
      this.sendingMessage = false;
      this.message.setValue('');
    });
  }

  cancelEdit(value: boolean) {
    if (value) {
      this.isEditing = false;
    } else {
      if (this.attachments.length) {
        this.isAttachmentEditing = false;
      }
    }
    this.ticketForm.patchValue(this.previousFormValue);
    this.attachments = [...this.previousAttachments];
    this.ticketForm.disable();
  }

  update() {
    const {
      memberId,
      assignedTo,
      statusTypeId,
      supportTypeId,
      departmentId,
      issueOrConcern,
      closingStatement,
      vendorId,
    } = this.ticketForm.value;
    const body = {
      memberId,
      statusTypeId,
      supportTypeId,
      vendorId,
      departmentId,
      issueOrConcern,
      assignedTo,
      closingStatement,
    };
    this.ticketsService
      .updateTicket(this.ticketId, body)
      .pipe(switchMap(() => this.fetchTicketDetail()))
      .subscribe({
        complete: () => {},
        error: (err: any) => {
          this.notificationService.error(err.message);
        },
        next: (res: any) => {
          this.isEditing = false;
          this.notificationService.success('Ticket updated successfully');
        },
      });
  }

  uploadAttachment() {
    this.isButtonLoading = true;
    const attachmentData = this.getAttachmentData();
    const deletedAttachmentIds = this.getDeletedAttachmentIds();
    forkJoin([
      attachmentData
        ? this.ticketsService.uploadAttachments(this.ticketId, attachmentData)
        : of(null),
      deletedAttachmentIds.length
        ? forkJoin(
            deletedAttachmentIds.map((id) =>
              this.ticketsService.deleteAttachment(this.ticketId, id)
            )
          )
        : of(null),
    ])
      .pipe(switchMap(() => this.fetchTicketDetail()))
      .subscribe((data) => {
        this.isAttachmentEditing = false;
        if (!this.attachments.length) {
          this.isAttachmentEditing = true;
        }
        this.isButtonLoading = false;
      });
  }

  deleteAttachment(id: number) {
    this.ticketsService
      .deleteAttachment(this.ticketId, id)
      .pipe(switchMap(() => this.fetchTicketDetail()))
      .subscribe((data) => {
        this.notificationService.success('Your ticket has been updated');
        this.isButtonLoading = false;
      });
  }

  getAttachmentData() {
    let filesAppended = false;
    const formData = new FormData();
    const attachments = this.ticketForm.get('attachments') as FormArray;
    attachments.controls
      .filter((control) => !control?.get('id')?.value)
      .forEach((control) => {
        const file: File = control?.value.file;
        formData.append('attachments', file, file.name);
        filesAppended = true;
      });
    return filesAppended ? formData : null;
  }

  getDeletedAttachmentIds() {
    const attachments = this.ticketForm.get('attachments') as FormArray;
    return attachments.controls
      .filter((control) => control?.get('deleted')?.value)
      .map((control) => {
        return control?.get('id')?.value;
      });
  }

  formatStatusKey(key: string): string {
    return key
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  openAttachmentDialog() {
    const dialogRef = this.dialogService.open({
      component: TicketAttachmentComponent,
      title: 'Upload Attachments',
      actionLabel: 'Upload',
      closeLabel: 'Cancel',
      hideCloseIcon: true,
      themeModal: true,
    });

    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$.subscribe(
      (formData: any) => {
        const formDataAttachments = formData.get('attachments').value;
        const attachmentsArray = this.ticketForm.get(
          'attachments'
        ) as FormArray;
        if (attachmentsArray) {
          attachmentsArray.clear();
          formDataAttachments.forEach((attachment: any) => {
            attachmentsArray.push(new FormControl(attachment));
          });
        }
        this.uploadAttachment();
        dialogRef.close();
      }
    );
  }

  linkMail() {
    this.dialogService.open({
      component: TicketLinkMailComponent,
      title: 'Link Email',
      actionLabel: 'Search & Link',
      closeLabel: 'Cancel',
      hideCloseIcon: true,
      themeModal: true,
    });
  }

  viewReminders() {
    const dialogRef = this.dialogService.open({
      component: TicketReminderComponent,
      title: 'Set Reminder',
      actionLabel: 'Submit',
      hideCloseIcon: true,
      themeModal: true,
      data: {
        ticketId: this.ticketId,
      },
    });
    this.dialogSubscription?.unsubscribe();
    this.dialogSubscription = this.dialogService.data$.subscribe((res: any) => {
      dialogRef.close();
    });
  }

  getSelectedVendorName() {
    const selectedVendorId = this.ticketForm.value.vendorId?.toString();
    const selectedVendor = this.vendorList.find(
      (vendor) => vendor.id.toString() == selectedVendorId
    );
    return selectedVendor ? selectedVendor.name : '';
  }

  handleEnter(event: KeyboardEvent) {
    event.preventDefault(); 
    this.sendComment();
  }
}
