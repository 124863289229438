import { Component, DestroyRef, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { DialogService } from '../../services/dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TicketsService } from '../tickets.service';
import { UploadComponent } from '../../components/upload/upload.component';
import { VendorElement, VendorsService } from '../../vendors/vendors.service';
import { NotificationService } from '../../services/notification.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { MatIconModule } from '@angular/material/icon';
import { ThemeUploadComponent } from "../../components/theme-upload/theme-upload.component";

@Component({
  selector: 'app-ticket-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    UploadComponent,
    MatIconModule,
    ThemeUploadComponent
],
  providers: [],
  templateUrl: './ticket-dialog.component.html',
  styleUrl: './ticket-dialog.component.scss',
})
export class TicketDialogComponent {
  assignees = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];
  memberTypes: any;
  supportTypes$ = this.ticketsService.getSupportTypes();
  departmentTypes$ = this.ticketsService.getDepartmentTypes();
  vendorList: VendorElement[] = [];
  supportForm = this.fb.group({
    memberId: ['', [Validators.required]],
    supportTypeId: ['', [Validators.required]],
    vendor: ['', [Validators.required]],
    departmentId: [''],
    attachments: this.fb.array([]),
    issueOrConcern: ['', [Validators.required]],
  });
  private readonly destroyRef = inject(DestroyRef);
  public isButtonDisabled: boolean = true;
  
  constructor(
    private ticketsService: TicketsService,
    private vendorService: VendorsService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private dataService: DataSharingService
  ) {
    this.ticketsService.getMetaDataMembers().subscribe((response:any) => {
      this.memberTypes = response;  
    });
  }

  ngOnInit() {
    this.dialogService.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.supportForm.valid) {
          this.dialogService.onData(this.createData());
        } else {
          this.supportForm.markAllAsTouched();
          this.notificationService.error('Please fill all required fields.');
        }
      });
    this.getVendorList();
    this.supportForm.statusChanges.subscribe((status: any) => {
      this.dataService.isFormValid.next(this.supportForm.valid);
    });
  }

  createData() {
    const { memberId, supportTypeId, departmentId, issueOrConcern, vendor } =
      this.supportForm.value;
    const body = {
      memberId,
      supportTypeId,
      vendorId: vendor,
      departmentId,
      issueOrConcern,
      assignedTo: '',
    };
    const formData = new FormData();
    formData.append(
      'details',
      new Blob([JSON.stringify(body)], { type: 'application/json' })
    );
    const attachments = this.supportForm.get('attachments') as FormArray;
    attachments.controls.forEach((control) => {
      const file: File = control?.get('file')?.value;
      formData.append('attachments', file, file.name);
    });
    return formData;
  }

  getVendorList() {
    this.vendorService.getVendors(0).subscribe((data: any) => {
      this.vendorList = data.vendors; 
    });
  }

  getSelectedVendorName() {
    const selectedVendorId = this.supportForm.get('vendor')?.value;
    const selectedVendor = this.vendorList.find(vendor => (vendor.id.toString() == selectedVendorId));
    return selectedVendor ? selectedVendor.name : '';
  }
}
