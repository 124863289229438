<app-header>
  <p title>Vendor Rebate</p>
</app-header>
<div class="main-content p-6">
  <div class="bg-white py-3 px-4 rounded-lg">
    <div class="flex justify-between">
      <div class="grid grid-cols-3 space-x-4">
        <div>
          <p class="title-gray">Description</p>
          <p><b>Rebate Program Description</b></p>
        </div>
        <div>
          <p class="title-gray">Created Date</p>
          <p><b>11/26/2024</b></p>
        </div>
        <div>
          <p class="title-gray">Created By</p>
          <p><b> John Smith</b></p>
        </div>
      </div>
      <button
        mat-stroked-button
        color="primary"
        class="theme-stroke-btn"
        (click)="createRebate()"
      >
        Create Rebate Type
      </button>
    </div>
  </div>
  <app-theme-container
    class="mt-5 block"
    (filter)="applyFilter($event)"
    title="Transactions"
    [totalRecords]="totalRecords"
    searchPlaceholder="Search Transactions by ID or Vendor"
    [cta]="'Create Transaction'"
    [publishBtn]="'Publish Selected'"
    (ctaClick)="openDialog()"
  >
    <app-theme-table
      #table
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [isLoading]="isLoading"
      (rowClick)="viewDetail($event.id)"
      (pageChange)="pageChange($event)"
      [totalPages]="totalPages"
      [totalRecords]="dataSource.length"
      [pageNumber]="pageNumber"
      searchPlaceholder="Search Transactions by ID or Vendor"
    >
      <ng-container *appCustomColumn="'status'; let item">
        @if (item.isPublished){
        <span class="green">Published</span>
        } @else {
        <span class="grey">Unpublished</span>
        }
      </ng-container>
      <ng-container *appCustomColumn="'action'; let item">
        <button class="flex justify-center w-full" [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="menu" class="actionIcons"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon svgIcon="detail"></mat-icon>
            <span>View Details</span>
          </button>
          <button mat-menu-item>
            <mat-icon svgIcon="download"></mat-icon>
            <span>Download CSV</span>
          </button>
          <button mat-menu-item>
            <mat-icon svgIcon="cross"></mat-icon>
            <span>Unpublish</span>
          </button>
          <button mat-menu-item>
            <mat-icon svgIcon="trash"></mat-icon>
            <span>Delete Transaction</span>
          </button>
        </mat-menu>
      </ng-container>
    </app-theme-table>
  </app-theme-container>
</div>
