<app-header>
  <p title>Finance</p>
</app-header>
<div class="main-content p-6">
  @if (incentiveProgramData){
  <app-theme-container
    [hideSearch]="true"
    [title]="'Incentive Programs'"
    [totalRecords]="incentiveProgramData.length"
    [cta]="'Create Incentive Program'"
    (ctaClick)="openDialog()"
  >
    <div class="p-4">
      <div class="grid grid-cols-3 gap-4">
        @if(incentiveProgramData.length) { @for (item of incentiveProgramData;
        track $index) {
        <button class="rebate-card" (click)="viewDetail(item.id)">
          <div class="flex items-start justify-between gap-1 w-[100%]">
            <div class="flex gap-3 items-start">
              <mat-icon svgIcon="dollar"></mat-icon>
              <h3>{{ item.name }}</h3>
            </div>
          </div>
          <p class="text-left">{{ item.description }}</p>
        </button>
        } } @else {
        <div class="no-records">
          <h6 class="text-gray-500">No current incentive programs available</h6>
        </div>
        }
      </div>
    </div>
  </app-theme-container>
  }
</div>
