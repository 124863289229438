<app-header>
  <p title>Quest Fuel</p>
</app-header>
<div class="main-content">
  @if (isLoading) {
  <div
    class="fixed flex h-[100vh] items-center justify-center w-[100vw] top-[0] left-[0] z-50"
  >
    <div class="absolute">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  }
  <div class="p-6">
    <div class="bg-white rounded-lg p-4">
      <div class="flex justify-between align-center">
        <h2 class="text-lg font-medium">Fuel Brands</h2>
        <button
          mat-stroked-button
          color="primary"
          class="cursor-pointer ml-auto px-2.5 theme-stroke-btn"
          (click)="addQuestFuel()"
        >
          Add/Update
        </button>
      </div>
      <form [formGroup]="brandDropdown">
        <mat-form-field appearance="outline" class="w-full mt-4">
          <mat-label>Fuel brand</mat-label>
          <mat-select
            formControlName="brandSelected"
            placeholder="Fuel brand"
            (selectionChange)="brandChangeHandler($event)"
          >
            @for (brand of brands$ | async; track brand) {
            <mat-option [value]="brand.value">
              {{ capitalize(brand.key) }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="bg-white rounded-lg mt-3 wrapper-height">
      <mat-tab-group
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        (selectedTabChange)="togglePricingHandler($event)"
      >
        <mat-tab label="Formula"> </mat-tab>
        <mat-tab label="Regular"> </mat-tab>
      </mat-tab-group>
      @if(dataSource && dataSource.length > 0 && dataSourceB &&
      dataSourceB.length > 0) {
      <table class="mt-3" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Location</th>
          <td mat-cell *matCellDef="let element">{{ element.location }}</td>
        </ng-container>
        <ng-container matColumnDef="regular">
          <th mat-header-cell *matHeaderCellDef>87-Regular</th>
          <td mat-cell *matCellDef="let element">${{ element.regular }}</td>
        </ng-container>
        <ng-container matColumnDef="super">
          <th mat-header-cell *matHeaderCellDef>93-Super</th>
          <td mat-cell *matCellDef="let element">${{ element.super }}</td>
        </ng-container>
        <ng-container matColumnDef="diesel">
          <th mat-header-cell *matHeaderCellDef>20-Diesel</th>
          <td mat-cell *matCellDef="let element">${{ element.diesel }}</td>
        </ng-container>
        <ng-container matColumnDef="bioDiesel">
          <th mat-header-cell *matHeaderCellDef>B20-Bio-Diesel</th>
          <td mat-cell *matCellDef="let element">${{ element.bioDiesel }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="dataSourceB"></tr>
        <tr mat-row *matRowDef="let row; columns: dataSourceB"></tr>
      </table>
      }
      @else if(!isLoading){
        <div class="no-records">
          <h6 class="text-gray-500">No records found</h6>
        </div>
      } 
    </div>
 
  </div>
</div>
